@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Linux Biolinum';
  }
  a {
    @apply underline;
  }
}
