// -----------------------------------------------------------------------------
// Font faces declarations
// -----------------------------------------------------------------------------

$font-styles: italic, normal;
$font-weights: (400, 700);

@each $style in $font-styles {
  @each $weight in $font-weights {
    @font-face {
      font-family: 'Linux Libertine';
      font-style: $style;
      font-weight: $weight;
      font-variant: normal;
      font-stretch: normal;
      src: url('#{$font-path}/LinuxLibertine-#{$weight}-#{$style}.ttf')
        format('truetype');
    }
  }
}

@font-face {
  font-family: 'Linux Biolinum';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/LinuxBiolinum-400-normal.woff') format('woff');
}

@font-face {
  font-family: 'Linux Biolinum';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/LinuxBiolinum-700-normal.woff') format('woff');
}

@font-face {
  font-family: 'Linux Biolinum';
  font-style: italic;
  font-weight: 400;
  src: url('#{$font-path}/LinuxBiolinum-400-italic.woff') format('woff');
}
