@layer components {
  .title {
    @apply uppercase text-center;
  }
  h1 {
    @apply text-2xl underline decoration-red;
  }
}

/* doesn't exists in tailwind */
.small-caps {
  font-variant-caps: small-caps;
}
